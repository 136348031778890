#checkoutStatusPaymentMethods .panel-white {
  padding: 0 15px;
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#checkoutStatusPaymentMethods .paymentContent {
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
}
#checkoutStatusPaymentMethods .paymentContent .btn {
  display: block;
  width: 100%;
}
#checkoutStatusPaymentMethods .paymentPartnerBlock {
  margin-bottom: 30px;
  font-size: 15px;
}
#checkoutStatusPaymentMethods .paymentPartnerBlock * {
  font-size: 15px;
}
#checkoutStatusPaymentMethods .paymentPartnerBlock .btn {
  display: block;
  width: 100%;
}
#checkoutStatusPaymentMethods .edenred .edenredLoginModal {
  width: 100%;
}
#checkoutStatusPaymentMethods .edenred .edenredLoginModal > div {
  display: block;
  width: 100%;
}
#checkoutStatusPaymentMethods .scalapay.head {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 14px;
}
#checkoutStatusPaymentMethods .scalapay.head > div {
  margin-right: 15px;
}
#checkoutStatusPaymentMethods .scalapay.head img {
  width: auto;
  height: 16px;
}
#checkoutStatusPaymentMethods .scalapay.head * {
  font-weight: bold;
  line-height: normal;
}
#checkoutStatusPaymentMethods .scalapay .btn,
#checkoutStatusPaymentMethods .scalapay .btn:hover,
#checkoutStatusPaymentMethods .scalapay .btn:focus {
  margin: 0;
  padding: 0;
  border: 0;
  display: inline;
  width: auto;
  font-weight: bold;
  text-decoration: underline;
  background: transparent;
  text-align: left;
}
#checkoutStatusPaymentMethods .satispay.head {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  font-size: 14px;
}
#checkoutStatusPaymentMethods .satispay.head > div {
  margin-right: 15px;
}
#checkoutStatusPaymentMethods .satispay.head img {
  width: auto;
  height: 19px;
}
#checkoutStatusPaymentMethods .satispay.head * {
  font-weight: bold;
  line-height: normal;
}
#checkoutStatusPaymentMethods .satispay .btn,
#checkoutStatusPaymentMethods .satispay .btn:hover,
#checkoutStatusPaymentMethods .satispay .btn:focus {
  margin: 0;
  padding: 0;
  border: 0;
  display: inline;
  width: auto;
  font-weight: bold;
  text-decoration: underline;
  background: transparent;
  text-align: left;
}
#checkoutStatusPaymentMethods .edenred .small-logo {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#checkoutStatusPaymentMethods .edenred .small-logo svg {
  width: 120px;
  margin-right: 15px;
}
#checkoutStatusPaymentMethods .edenred .small-logo * {
  font-weight: bold;
}
#checkoutStatusPaymentMethods .edenred .edenredLoginModal,
#checkoutStatusPaymentMethods .edenred .textOnly {
  display: inline;
}
#checkoutStatusPaymentMethods .stripe.head {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#checkoutStatusPaymentMethods .stripe.head > div {
  margin-right: 15px;
}
#checkoutStatusPaymentMethods .stripe.head img {
  width: auto;
  height: 24px;
  margin-right: 8px;
}
#checkoutStatusPaymentMethods .stripe.head * {
  font-weight: bold;
  line-height: normal;
}
#checkoutStatusPaymentMethods .welfare .head {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
#checkoutStatusPaymentMethods .welfare .head * {
  font-weight: bold;
}
#checkoutStatusPaymentMethods .welfare .head .icon {
  margin-right: 15px;
}
#checkoutStatusPaymentMethods .welfare .head svg {
  width: auto !important;
  height: 48px !important;
  margin-top: 5px;
}
#checkoutStatusPaymentMethods .head {
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
#checkoutStatusPaymentMethods .panel {
  min-height: 68px;
  margin-bottom: 30px;
}
.sidebarCheckoutPublic .privacy-box {
  font-size: 14px;
  line-height: 120%;
}
.sidebarCheckoutPublic .estrattoConto {
  padding-left: 2.5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-bottom: 1rem;
}
.sidebarCheckoutPublic .estrattoConto.title {
  padding-left: 0;
  color: #333333;
  min-width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 18px;
  margin-bottom: 1rem;
}
.sidebarCheckoutPublic .estrattoConto.title svg {
  margin-right: 1rem;
  margin-right: 1rem;
  height: 1.25rem;
  width: auto;
}
.sidebarCheckoutPublic .estrattoConto .copy {
  line-height: 1;
}
.sidebarCheckoutPublic .estrattoConto .spacer {
  border-bottom: 1px solid #333333;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  margin-left: 0.25rem;
  margin-right: 0.25rem;
  line-height: 1;
}
.sidebarCheckoutPublic .estrattoConto .value {
  font-weight: bold;
  line-height: 1;
}
.sidebarCheckoutPublic .estrattoConto .info {
  min-width: 100%;
  font-size: 75%;
  padding-top: 0.5rem;
  text-align: left;
  line-height: 120%;
}